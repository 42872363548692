import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '@hrmony/component-library/scripts/event-emitter'

export const identifier = 'file-input-with-preview'

const FileInputWithPreviewComponent: TComponentInit = (element) => {
	const input = element.querySelector(
		'input[type="file"]',
	) as HTMLInputElement | null
	const previewWrapper = element.querySelector('[data-preview]')
	const fallbackWrapper = element.querySelector('[data-fallback]')

	console.log({ input, previewWrapper })

	const loadPreviews = () => {
		if (previewWrapper) {
			previewWrapper.innerHTML = ''
		}

		// generate previews for each selected file
		// and append it to the preview wrapper element
		if (input?.files) {
			console.log(input?.files)
			;[...input.files].forEach((file) => {
				const previewItemElement = document.createElement('figure')
				previewItemElement.classList.add(`${identifier}__preview-item`)
				if (file.type.match('application/pdf') !== null) {
					throw new Error()
				} else if (file.type.match('image/') !== null) {
					const fileUrl = window.URL.createObjectURL(file)
					const previewImage = document.createElement('img')

					previewImage.classList.add(`${identifier}__preview-item-image`)
					previewImage.onload = () => {
						window.URL.revokeObjectURL(fileUrl)
					}
					previewImage.src = fileUrl

					const previewCaption = document.createElement('figcaption')
					previewCaption.classList.add(
						`${identifier}__preview-item-name`,
						'visually-hidden',
					)
					previewCaption.innerText = `${file.name}`

					previewItemElement.append(previewCaption)
					previewItemElement.append(previewImage)
				} else {
					throw new Error()
				}

				previewWrapper?.append(previewItemElement)
			})
		} else {
			throw new Error()
		}
	}

	const onChange = () => {
		console.log('onChange')
		emitter.emit('loading:start')

		try {
			loadPreviews()

			fallbackWrapper?.setAttribute('hidden', '')
			previewWrapper?.removeAttribute('hidden')
		} catch {
			previewWrapper?.setAttribute('hidden', '')
			fallbackWrapper?.removeAttribute('hidden')
		}

		emitter.emit('loading:stop')
	}

	function init() {
		input?.addEventListener('change', onChange)

		onChange()
	}

	function destroy() {
		input?.removeEventListener('change', onChange)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, FileInputWithPreviewComponent)

export default {
	identifier,
	FileInputWithPreviewComponent,
}
