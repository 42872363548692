import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import type { EssenszuschussUploadOverlayConfig } from './EssenszuschussUploadOverlay.shared'

export const identifier = 'essenszuschuss-upload-overlay'

const EssenszuschussUploadOverlayComponent: TComponentInit = (element) => {
	// window['essenszuschuss-upload-form-file-input'].files = window['test'].files
	const config: EssenszuschussUploadOverlayConfig = JSON.parse(
		element.getAttribute('data-component-config')!,
	)
	const decodeHelperElement = document.createElement('textarea')

	async function init() {
		try {
			const response = await fetch(`${config.action}?configOnly=true`, {
				headers: {
					'HX-Request': 'true',
				},
			})

			decodeHelperElement.innerHTML = await response.text()

			const formConfig = JSON.parse(decodeHelperElement.value)

			console.log(formConfig)
		} catch (error) {
			console.log(error)
		}
	}

	function destroy() {}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, EssenszuschussUploadOverlayComponent)

export default {
	identifier,
	EssenszuschussUploadOverlayComponent,
}
